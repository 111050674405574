<template>
  <img :loading="lazyLoadImg ? 'lazy' : null" :src="default_url" :alt="alt" :srcset="srcset" />
</template>

<script>
export default {
  name: 'SmartImg',
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: false,
    },
    ratio: {
      type: Number,
      required: false,
    },
    lazyLoadImg: {
      type: Boolean,
      required: false,
      default: true,
    },
    preload: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      default_url: '/img/placeholder.png',
      srcset: '',
      widths: [150, 180, 210, 300, 360, 420, 588, 720, 1176, 1440],
      default_width: 300,
    };
  },
  mounted() {
    if (this.preload) this.addPreloadLink(this.src);
  },
  watch: {
    src: {
      immediate: true,
      handler(r) {
        this.parseImageUrl(this.src);
      },
    },
  },
  created() {},
  methods: {
    parseImageUrl: function (url) {
      if (!url) return;

      if (url.includes('tourscanner.com')) {
        if (url.includes('blog/wp-content/uploads/')) this.formatTourscannerImg(url);
        else this.default_url = url;

        return;
      }

      this.formatProviderImg(url);
    },

    addPreloadLink(url) {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = url;
      document.head.appendChild(link);
    },

    makeDynamicSrcset: function (parametrized_url, widths, format_callback = null) {
      let srcset = '';
      let height = null;
      if (!format_callback)
        format_callback = function (parametrized_url, width, height = null) {
          parametrized_url = parametrized_url.replace('[w]', width);
          if (height) parametrized_url = parametrized_url.replace('[h]', height);

          return parametrized_url;
        };

      widths.forEach((width) => {
        if (this.ratio) height = Math.round(width * this.ratio);

        let formatted_url = format_callback(parametrized_url, width, height);

        srcset = srcset.concat(formatted_url, ' ', width / 2, 'w, ');

        if (width === this.default_width) this.default_url = formatted_url;
      });

      this.srcset = srcset.slice(0, -2);
    },

    resizeViatorImage: function (url, width, height = null) {
      const regex = /spp-(\w{7})/;
      if (width <= 200 && height <= 200) return url.replace(regex, 'spp-200x200');
      if (width <= 400 && height <= 400) return url.replace(regex, 'spp-400x400');
      if (width <= 674 && height <= 446) return url.replace(regex, 'spp-674x446');
      return url.replace(regex, 'spp-720x480');
    },
    resizeGygImage: function (url, width, height = null) {
      // https://cdn.getyourguide.com/img/tour/64bfa63ad453a.jpeg/58.jpg
      let best_format = '58.jpg';

      if (width <= 90 && height <= 90) best_format = '52'; // 90x90
      else if (width <= 180 && height <= 180) best_format = '53'; // 180x180
      else if (width <= 320 && height <= 290) best_format = '54'; // 320x290
      else if (width <= 480 && height <= 320) best_format = '60'; // 480x320
      else if (width <= 640 && height <= 580) best_format = '55'; // 640x580
      else if (width <= 768 && height <= 696) best_format = '63'; // 768x696
      else best_format = '74'; // 1102x735

      return url.replace('58.jpg', best_format + '.jpg');
    },

    formatHelloticketsImage: function (url) {
      /*  from:
      https://res.cloudinary.com/instant-tickets/image/upload/v1468950375/rcmx4cjop1wenlxrrgmf.jpg
      to:
      https://res.cloudinary.com/instant-tickets/image/upload/c_limit,h_600/v1468950375/rcmx4cjop1wenlxrrgmf.jpg */

      var split_part = '/image/upload/';
      var parsedUrl = url;
      var res = url.split(split_part);
      if (res.length > 1) {
        parsedUrl = res[0] + split_part + 'c_limit,w_[w]' + (this.ratio ? '&h=[h]' : '') + '/' + res[1];
      }
      return parsedUrl;
    },
    formatTiqetsImage(url) {
      /* Tiqets pictures pattern
   https://aws-tiqets-cdn.imgix.net/images/content/f56fae78122247d799ec87d48b156360.jpg?auto=format&fit=crop&h=250&ixlib=python-1.1.2&q=70&w=250&s=56d6c4dd7c5632f5a650f970e891a2c2 */
      url = url.replace('&h=250', '');
      url = url.replace('&w=250', '');
      return url + '&w=[w]' + (this.ratio ? '&h=[h]' : '');

      /* url = url.replace('&fit=crop', '&fit=clip'); */
    },
    formatMusementImage(url) {
      return this.formatImgixImage(url.replace(/\?.*$/, ''));
    },
    formatImgixImage(url) {
      const paramChar = url.indexOf('?') !== -1 ? '&' : '?';

      return url + paramChar + 'fit=crop&w=[w]' + (this.ratio ? '&h=[h]' : '');
    },
    formatKlookImage(url) {
      url = url.replace(/\bw_[^\/,]+\b/, 'w_[w]');
      if (this.ratio) url = url.replace(/\bh_[^\/,]+\b/, 'h_[h]');
      return url;
    },
    formatFreedomeImage(url) {
      if (url.indexOf('?') !== -1) url += '&width=[w]';
      else url += '?width=[w]';

      if (this.ratio) url += '&height=[h]';

      return url;
    },

    formatProviderImg: function (url) {
      let format_callback = null;

      if (!url.startsWith('https') && url.startsWith('http')) {
        url = 'https' + url.slice(4);
      }

      if (url.indexOf('media-cdn.tripadvisor') !== -1) {
        format_callback = this.resizeViatorImage;
      } else if (url.indexOf('cdn.getyourguide') !== -1) {
        format_callback = this.resizeGygImage;
      } else if (url.indexOf('//aws-tiqets-cdn') !== -1) {
        url = this.formatTiqetsImage(url);
      } else if (url.indexOf('cdn.freedome') !== -1) {
        url = this.formatFreedomeImage(url);
      } else if (url.indexOf('res.cloudinary.com') !== -1) {
        url = this.formatHelloticketsImage(url);
      } else if (
        url.indexOf('//cdn-imgix') !== -1 ||
        url.indexOf('imgix.net') !== -1 ||
        url.indexOf('images.musement') !== -1
      ) {
        if (url.indexOf('images.musement') !== -1) url = this.formatMusementImage(url);
        else url = this.formatImgixImage(url);
      } else if (url.indexOf('res.klook.com') !== -1) {
        url = this.formatKlookImage(url);
      } else {
        // TODO find out who this is and if we still use them
        url = url.replace('graphicslib/', 'graphicslib/thumbs360x240/');

        url = url.replace('splice-spp-674x446/', 'splice-spp-360x240/');
      }

      this.makeDynamicSrcset(url, this.widths, format_callback);
    },
    formatTourscannerImg: function (WPUrl) {
      // replace the WordPress URL with the Cloudflare CDN URL
      const CDNUrl = WPUrl.replace('blog/wp-content/uploads/', 'images/');
      /* Add public variant to src and create srcset and sizes attributes (only for raster images).
      We have variants on Cloudflare at the following widths:
      150
      180
      210
      300
      360
      420
      588
      720
      1176
      1440
      Each variant has a max height of twice its width, to accommodate different image ratios.
      The src attribute acts as a fallback in case srcset fails for some reason. */
      this.default_url = CDNUrl.concat('/public');

      if (!WPUrl.includes('.svg')) {
        this.makeDynamicSrcset(CDNUrl.concat('/w[w]'), this.widths);
        // this.sizes = '(max-width: 500px) 500px, (max-width: 800px) 800px, 1280px';
      }
    },
  },
};
</script>

<script setup></script>

<style scoped></style>
